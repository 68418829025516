<template>
  <div
    class="columns form is-mini-gap is-multiline"
  >
    <div class="column is-narrow">
      <a-input-number
        v-model:value="year"
        :size="size"
        :placeholder="$t('Year')"
        @change="handleFilter"
      />
    </div>
    <div class="column is-narrow">
      <InputState
        v-model:value="prefecture"
        style="min-width: 200px;"
        country-code="jp"
        value-locale="ja"
        :placeholder="$t('Prefecture')"
        @change="handleFilter"
      />
    </div>
    <div class="column">
      <a-button
        v-show="year||prefecture"
        :size="size"
        style="min-width: 110px;"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { cleanObject } from '@/utils/util';
import InputState from '@/components/InputState';

export default {
  name: 'PrefectureFilter',
  components: {
    InputState,
  },
  emits: ['filter'],
  data() {
    return {
      size: 'large',
      year: undefined,
      prefecture: undefined,
    };
  },
  mounted() {
    this.year = this.$route.query.year || undefined;
    this.prefecture = this.$route.query.prefecture || undefined;
  },
  methods: {
    handleFilter: debounce(async function () {
      const query = cleanObject({
        ...this.$route.query,
        year: this.year,
        prefecture: this.prefecture,
        page: 1, // force page 1
      });

      await this.$router.push({ query });
      this.$emit('filter');
    }, 1000),
    handleClear() {
      this.year = undefined;
      this.prefecture = undefined;
      this.handleFilter();
    },
  },
};
</script>
