<template>
  <a-form
    ref="forms"
    :model="form"
    :rules="rules"
    class="columns is-mini-gap is-multiline prefecture-occ-form"
  >
    <div class="column is-12">
      <h2 class="ant-modal-title has-text-primary has-text-weight-bold is-size-4">
        {{ title }}
      </h2>
    </div>
    <a-form-item
      class="column is-12"
      :label="$t('Year')"
      name="year"
    >
      <a-input-number
        v-model:value="form.year"
        :size="size"
        class="year-input"
        @change="(val) => form.year = val.toString()"
      />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Prefecture')"
      name="prefecture"
    >
      <div class="is-flex">
        <InputState
          v-model:value="form.prefecture"
          style="width: 100%;"
          country-code="jp"
          value-locale="ja"
        />
      </div>
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Average OCC')"
      name="avgOCC"
    >
      <div class="is-flex">
        <a-input-number
          v-model:value="form.avgOCC"
          style="width: 100%;"
          :size="size"
        />
        <span class="unit-width">%</span>
      </div>
    </a-form-item>
    <div
      class="columns is-12"
      style="padding: 5px; width: 102%;"
    >
      <a-form-item
        class="column is-6"
        :label="$t('Ryokan OCC')"
        name="ryokanOCC"
      >
        <div class="is-flex">
          <a-input-number
            v-model:value="form.ryokanOCC"
            style="width: 100%;"
            :size="size"
          />
          <span class="unit-width">%</span>
        </div>
      </a-form-item>
      <a-form-item
        class="column is-6"
        :label="$t('Resort OCC')"
        name="resortOCC"
      >
        <div class="is-flex">
          <a-input-number
            v-model:value="form.resortOCC"
            min="0"
            style="width: 100%;"
            :size="size"
          />
          <span class="unit-width">%</span>
        </div>
      </a-form-item>
    </div>
    <div
      class="columns is-12"
      style="padding: 5px; width: 102%;"
    >
      <a-form-item
        class="column is-6"
        :label="$t('Business Hotel OCC')"
        name="businessHotelOCC"
      >
        <div class="is-flex">
          <a-input-number
            v-model:value="form.businessHotelOCC"
            min="0"
            style="width: 100%;"
            :size="size"
          />
          <span class="unit-width">%</span>
        </div>
      </a-form-item>
      <a-form-item
        class="column is-6"
        :label="$t('City OCC')"
        name="cityOCC"
      >
        <div class="is-flex">
          <a-input-number
            v-model:value="form.cityOCC"
            min="0"
            style="width: 100%;"
            :size="size"
          />
          <span class="unit-width">%</span>
        </div>
      </a-form-item>
    </div>
    <div
      class="column has-text-right is-12 m-t-4x"
    >
      <a-button
        :disabled="isSubmitting"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px;"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 115px;"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import number from '@/filters/number';
import { round } from '@/views/simulations/components/utils';
import InputState from '@/components/InputState';

const format = (v) => Number(number(v * 100, { useGrouping: false }));

export default {
  name: 'PrefectureForm',
  components: {
    InputState,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['cancel', 'close'],
  data() {
    return {
      size: 'large',
      isSubmitting: false,
      form: {
        year: undefined,
        prefecture: undefined,
        avgOCC: undefined,
        ryokanOCC: undefined,
        resortOCC: undefined,
        businessHotelOCC: undefined,
        cityOCC: undefined,
      },
      rules: {
        year: [
          {
            len: 4,
            message: this.$t('Year must be valid year.'),
            trigger: ['change', 'blur'],
          },
          {
            required: true,
            message: this.$t('Year is required'),
            trigger: ['change', 'blur'],
          },
        ],
        prefecture: [
          {
            required: true,
            message: this.$t('Prefecture is required'),
            trigger: ['change', 'blur'],
          },
        ],
        avgOCC: [
          {
            required: true,
            type: 'number',
            message: this.$t('Average OCC is required'),
            trigger: ['change', 'blur'],
          },
        ],
        ryokanOCC: [
          {
            required: true,
            type: 'number',
            message: this.$t('Ryokan OCC is required'),
            trigger: ['change', 'blur'],
          },
        ],
        resortOCC: [
          {
            required: true,
            type: 'number',
            message: this.$t('Resort OCC is required'),
            trigger: ['change', 'blur'],
          },
        ],
        businessHotelOCC: [
          {
            required: true,
            type: 'number',
            message: this.$t('Business Hotel OCC is required'),
            trigger: ['change', 'blur'],
          },
        ],
        cityOCC: [
          {
            required: true,
            type: 'number',
            message: this.$t('City OCC is required'),
            trigger: ['change', 'blur'],
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return this.value && this.value.id
        ? this.$t('Edit prefecture occ')
        : this.$t('Add prefecture occ');
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv) {
          this.form.year = nv.year.toString() || undefined;
          this.form.prefecture = nv.prefecture || undefined;
          this.form.avgOCC = format(nv.avgOCC) || undefined;
          this.form.ryokanOCC = format(nv.ryokanOCC) || undefined;
          this.form.resortOCC = format(nv.resortOCC) || undefined;
          this.form.businessHotelOCC = format(nv.businessHotelOCC) || undefined;
          this.form.cityOCC = format(nv.cityOCC) || undefined;
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        console.log(this.form);
        await this.$refs.forms.validate();
        this.isSubmitting = true;
        if (this.value && this.value.id) {
          const payload = { id: this.value.id, ...this.getPayload() };
          await this.$store.dispatch('simulations/updatePrefecture', payload);
        } else {
          const payload = { ...this.getPayload() };
          await this.$store.dispatch('simulations/addPrefecture', payload);
        }

        this.isSubmitting = false;
        this.$emit('close');
        this.resetFields();
      } catch (error) {
        this.isSubmitting = false;
      }
    },
    getPayload() {
      const normalize = (v) => round(v / 100, 4); // 4 decimal

      return {
        year: this.form.year,
        prefecture: this.form.prefecture,
        avgOCC: normalize(this.form.avgOCC),
        ryokanOCC: normalize(this.form.ryokanOCC),
        resortOCC: normalize(this.form.resortOCC),
        businessHotelOCC: normalize(this.form.businessHotelOCC),
        cityOCC: normalize(this.form.cityOCC),
      };
    },
    resetFields() {
      this.$refs.forms.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.unit-width {
  width: 20px;
  text-align: left;
  margin-left: 10px;
}

.prefecture-occ-form::v-deep {
  .ant-input-number-handler-wrap {
      display: none;
    }

  .ant-input-number-input {
    text-align: right;
  }

  .year-input {
    width: 100%;

    .ant-input-number-input {
      text-align: left;
    }
  }
}

</style>
